@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
html {
  scroll-behavior: smooth;
}
.topbtn {
  position: absolute;
  right: 28px;
  margin-top: -5rem;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #ffcc04;
  cursor: pointer;
  z-index: 9999;
  opacity: 0.9;
}
i {
  color: #ffffff;
  font-size: larger;
}
a {
  color: rgb(255 255 255);
  text-decoration: underline;
}
.whatsapp-chat-container {
  position: fixed;
  bottom: 90px;
  right: 28px;
  z-index: 1000;
}
.whatsapp-icon {
  background-color: #25d366;
  border: none;
  border-radius: 50%;
  padding: 5px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-box {
  position: absolute;
  bottom: 70px;
  right: 0;
  width: 250px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}
.chat-box textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  resize: none; /* Prevent resizing */
}
.send-button {
  background-color: #25d366;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end; /* Aligns button to the right */
}
.chat-box {
  position: absolute;
  bottom: 70px; /* Adjust based on your preference */
  right: 0;
  width: 250px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
}
.chat-box textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}
.send-button {
  background-color: #25d366;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}
@media screen and (max-width: 973px) {
  .whatsapp-icon {
    background-color: #25d366;
    border: none;
    border-radius: 50%;
    padding: 7px;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .whatsapp-chat-container {
    position: fixed;
    bottom: 81px;
    right: 6px;
    z-index: 1000;
  }
  .topbtn {
    position: absolute;
    right: 7px;
    margin-top: -5rem;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #ffcc04;
    cursor: pointer;
    z-index: 9999;
    opacity: 0.9;
  }
}
.leader_img img {
  width: 100%;
}
.center-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.center-vertical h2 {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}
.smallscreen {
  display: none;
}
@media (max-width: 767px) {
  .largescreen {
    display: none;
  }
  .smallscreen {
    display: block;
  }
  .founder_height {
    height: 50vh;
  }
}
@media (min-width: 768px) {
  .smallscreen {
    display: none;
  }
  .flex_left {
    align-items: flex-start;
  }
  .flex_right {
    align-items: flex-end;
  }
}
