.usp{
  overflow:hidden;
}
.uspimg img {
  max-width: 100px;
  height: auto;
  vertical-align: middle;
  display: inline-block;
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.usp_card {
  margin: 0px 0px 40px 0px;
  padding: 20px 20px 20px 25px;
  background-color: #ffffff;
  border-radius: 49px 10px 10px 10px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.usp_card:hover{
  background-color: #ffffffbd;
}
.usp_card h4 {
  color: #d42121;
  font-size: 20px;
  font-weight: 600;
}
.usp_card h5 {
  color: #19171A;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  text-align:justify;
}
.usp_heading h4 {
  color: #19171A;
  font-family: "Calistoga", serif;
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
  font-style: normal;
  text-decoration: none;
}
.usp_heading h3 {
  color: #fff;
  text-align: center;
  font-family: "Calistoga", serif;
  font-size: 50px;
  font-weight: 300;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: -0.5px;
}
@media screen and (max-width: 973px) {
  .uspimg img {
    margin-top: 130px;
  }
}
@media screen and (max-width: 425px) {
    .uspimg img {
      margin-top: 0px;
    }
  }