@import url(https://fonts.googleapis.com/css2?family=Calistoga&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scroll-behavior: smooth;
}



.usp{
  overflow:hidden;
}
.uspimg img {
  max-width: 100px;
  height: auto;
  vertical-align: middle;
  display: inline-block;
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.usp_card {
  margin: 0px 0px 40px 0px;
  padding: 20px 20px 20px 25px;
  background-color: #ffffff;
  border-radius: 49px 10px 10px 10px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.usp_card:hover{
  background-color: #ffffffbd;
}
.usp_card h4 {
  color: #d42121;
  font-size: 20px;
  font-weight: 600;
}
.usp_card h5 {
  color: #19171A;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  text-align:justify;
}
.usp_heading h4 {
  color: #19171A;
  font-family: "Calistoga", serif;
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
  font-style: normal;
  text-decoration: none;
}
.usp_heading h3 {
  color: #fff;
  text-align: center;
  font-family: "Calistoga", serif;
  font-size: 50px;
  font-weight: 300;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: -0.5px;
}
@media screen and (max-width: 973px) {
  .uspimg img {
    margin-top: 130px;
  }
}
@media screen and (max-width: 425px) {
    .uspimg img {
      margin-top: 0px;
    }
  }
.visiondiv h4{
    color: #d42121;
    font-size: 30px;
    font-weight: 600;
    text-align:center;
}
.visiondiv {
    margin: 0px 0px 40px 0px;
    padding: 20px 20px 20px 25px;
    background-color: #ffffff;
    border-radius: 10px 10px 10px 10px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }
  .visiondiv p {
    text-align: justify;
    color: #19171a;
    font-family: "Montserrat", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 1.7em;
  }
  .visiondiv:hover{
    background-color: #ffffffbd;
  }
  .VisionHeading h3{
    color: #d42121;
    text-align: center;
    font-family: "Calistoga", serif;
    font-weight: 300;
    font-style: normal;
    font-size: 34px;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 1.5em;
    word-spacing: 2px;
    letter-spacing: 0.2px;

  }
  .VisionHeading h3 span:nth-child(1) {
    color:white ; 
    background-color:#d42121a3;
    padding: 0 18px 0 20px;
    margin-right:12px;
  }  
  .VisionHeading h3 span:nth-child(2) {
    color:black;
    padding: 0 0 0 18px;
  }
  .VisionHeading h3 span:nth-child(3) {
    color:white ; 
    margin-left:22px;
    background-color:#d42121a3;
    padding: 0 18px 0 18px;
  }
  .VisionHeading h3 span:nth-child(4){
    color:white ; 
    background-color:#d42121a3;
    padding: 0 18px 0 20px;
    margin-right:12px;
  }
  .visionimg{
    max-width:50%;
    height:auto;
  }
  .Visioncontainer{
    position:relative;
    top:-95px;
  }
  .visionhandimg{
    position: absolute;
    max-width: 45%;
    top: 80px;
    right: -22px;
  }
  .missioncontainer{
    margin-top: 124px;
  }
  @media screen and (max-width: 973px){
     .VisionHeading h3{
        font-size: 18px;
        
      }  .visionhandimg{
        position: absolute;
        max-width: 45%;
        top: 118px;
        right: 0px;
      } .missioncontainer{
        margin-top: 100px;
      }
      .VisionHeading h3 span:nth-child(1) {
        color:white ; 
        background-color:#d42121a3;
        padding: 0 6px 0 6px;
        margin-right:4px;
      }      
      .VisionHeading h3 span:nth-child(2) {
        color:black;
        padding: 0 0 0 0px;
      }
      .VisionHeading h3 span:nth-child(3) {
        color:white ; 
        margin:0px;
        background-color:#d42121a3;
        padding: 0 6px 0 6px;
      }
      .VisionHeading h3 span:nth-child(4){
        padding: 0 6px 0 6px;
        margin:0px;
      }
  }
  @media screen and (max-width: 475px){   
      .visionhandimg{
        position: relative;
        max-width: 112%;
        top:0px;
        right: -42px;
      }
      .missioncontainer{
        margin-top: 29px;
      }
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0; 
    }
    100% {
      transform: translateX(0); 
      opacity: 1;
    }
  }
  .animate-slide-in {
    animation: slideInFromRight 2s ease-out; 
  }
.gallery-tab .btn-warning{
    width:20%;
    padding:15px;
    color:#fff;
    font-size:1.2rem;
    margin-left: 12px;
    margin-right: 12px;
    box-shadow: 0 3px 5px rgba(130, 126, 126, 0.3);
    background-color: #d42121;
    border: white 1px solid;
    border-radius: 0%;  
}
.gallery-tab .btn-warning:hover{
    background-color: #EEA820  ;
    color: white;
    transition: 1.0s;
}
.active:focus{
    background-color: #d42121;
    color: white;
}
.image-container .image{
    border: 15px solid #fff;
    overflow: hidden;
    box-shadow: 0 3px 5px rgba(0,0,0,0.3);
}
.image-container .image img{
    transition: transform 2.5s ease;
    overflow:hidden;
    object-fit: cover;
}
.image-container .image:hover img{
    transform:scale(1.4);
}
#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
  width: 100%;
  background: #eee;
  font-family: "Montserrat", "Poppins", sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
.justify {
  text-align: justify;
  color: #19171a;
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.7em;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  display: none;
}
.mainheading {
  color: #d42121;
  text-align: center;
  font-family: "Calistoga", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: -0.5px;
}
.centerheading h3 {
  color: #d42121;
  text-align: center;
  font-family: "Calistoga", serif;
  font-weight: 300;
  font-style: normal;
  font-size: 50px;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: -0.5px;
}
.mainheading span {
  font-family: "Montserrat", "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: black;
}
.line {
  width: 120px;
  height: 2px;
  background: #eea820;
  margin: 0px auto;
  border-radius: 5px;
}
.founder_txt {
  text-align: center;
  color: #19171a;
  font-family: "Montserrat", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.7em;
}
.subheding {
  text-align: justify;
  color: #fffffe;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.5em;
  letter-spacing: 0px;
}
.TopHeading {
  font-size: clamp(1.5rem, 2vw, 1rem);
  font-weight: 600;
  text-align: center;
  margin: 20px 0;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  padding-bottom: 13px;
}
.swiper {
  width: 110%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  flex-shrink: 0;
  width: 39% !important;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.wrapperr {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}
.wrapperr iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.swiper-slide img {
  display: block;
  width: 100%;
  box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.1) !important;
}
.textanim {
  display: flex;

  justify-content: center;
  align-item: center;
}
.textanim img {
  width: 9%;
  position: relative;
  top: -30px;
}
#textanim1 h2 {
  position: relative;
  top: 49px;
}

@keyframes animate {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.contactbg {
  background-image: url("/static/media/CITY WEBSITE EDITS-01.1627a3aa.webp");
}
.cBVEOn {
  position: relative;
  right: 0px;
  width: 287px;
  height: 346px;
}
@media screen and (max-width: 973px) {
  .swiper-slide {
    width: 64% !important;
  }
  .TopHeading1 {
    font-size: 22px;
  }
  .TopHeading {
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
  }
  .textanim img {
    width: 12%;
    position: relative;
    top: -16px;
  }
  #textanim1 h2 {
    position: relative;
    top: 68px;
    font-size: 4vw;
  }
  .image-container .image {
    border: 9px solid #fff;
  }
  .gallery-tab .btn-warning {
    width: 100%;
    padding: 10px;
  }
  .mainheading {
    font-size: 22px;
    display: contents;
    text-align: center;
  }
  .text-center h2 {
    display: grid;
  }
  .centerheading h3 {
    font-size: 28px;
  }
  .usp_heading h3 {
    font-size: 28px;
  }
  .justify {
    font-size: 12px;
  }
}
.instaframe {
  height: auto;
  max-width: 100%;
}
.whatsapp-chat-container {
  animation-name: infiniteupdown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  transition: all 0.3s;
}
.readbtn{
    border: none;
    outline: none;
    outline-style: none;
    font-size: 14px;
    color: #999090;
    font-family: "Montserrat", Sans-serif;
    font-weight: 600;
}
.btn {
  background-color: #d42121;
  border: 2px solid #d42121;
  border-radius: 15px;
  padding: 10px 24px 10px 24px;
  font-size: 18px;
  color: #fff;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.btn_text {
  text-decoration: none;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.btn:hover {
  background-color: #eea820;
  border: 2px solid #eea820;
  color: #fff;
}
.visiondiv {
  color: #ffcc04;
  text-align: center;
  font-family: "Montserrat", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: -0.5px;
}
#home {
  position: relative;
  top: -92px;
}
.taggbox_main a {
  display: none !important;
}
.productvideo{
  position:absolute;
}
.productvideo h3{
  color: #fff;
    font-family: "Calistoga", serif;
    font-weight: 300;
    font-size: 50px;
}
.swiper-container-wrapper {
  position: relative;
}
.custom-swiper-button {
  position: absolute;
  top: 50%;
  transform: translateY(-15%);
  z-index: 10;
  color:#fff;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}
.swiper-button-prev {
  left: 30px;
  color:#fff !important;
}
.swiper-button-next {
  right: 30px;
  color:#fff !important;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.centerheading {
  text-align: center;
}

@media screen and (max-width: 768px){
  .productvideo h3{
      font-size: 20px;
  }
  .btn {
    border-radius: 8px;
    padding: 6px;
    font-size: 14px;
}
}
@media screen and (min-width: 1400px){
  .productvideo{
    position: absolute;
    margin-top: 98px;
  }  
}
.people img {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.people img.slide-up {
  opacity: 1;
  transform: translateY(0);
}
.scroll_hidden {
  overflow: hidden;
  position: absolute;
  margin-top: -28px;
}
.scroll {
  white-space: nowrap;
  margin: 0 2em;
}
.Map {
  position: relative;
}
.overlay-heading {
  position: absolute;
  top: 12%;
  left: 46%;
  width:50%;
  transform: translate(-50%, -50%);
  color: black !important;
  z-index: 1;
  text-align: center;
  padding: 0 10px;
}
.Map img {
  width: 100%;
  position: relative;
}
.image-right{
  text-align:right;
}
.image-right img{
  float:right;
}
.csMap{
  animation-name:infiniteupdown;
  animation-duration:3s;
  animation-iteration-count:infinite;
  animation-timing-function:ease-out;
  transition: all .3s;
}
@keyframes infiniteupdown{
  0%{
    transform:translateY(0px);
  }
  50%{
    transform:translateY(15px);
  }
  100%{
    transform:translateY(0px);
  }
}
@keyframes infiniteleftright{
  0%{
    transform:rotate(5deg);
  }
  50%{
    transform:rotate(-5deg);
  }
  100%{
    transform:rotate(5deg);
  }
}
.scroll div {
  display: flex;
  grid-gap: 4em;
  gap: 4em;
}
.txtmap {
  color: white;
  font-weight: bold;
  line-height: 10px;
  padding: 6px;
}
.scrollheading h3 span:nth-child(3){
  font-size: 40px !important;
  line-height: 70px !important;
  background: green !important;
}
@media screen and (max-width: 973px) {
  .logoanimation {
    width: 22%;
    position: relative;
    left: 50px;
  }
}
@media screen and (max-width:758px){
  .overlay-heading {
    font-size:9px !important;
  }
  .scrollheading h3 span:nth-child(3){
    font-size: 14px !important;
    line-height: 24px !important;
    background: green !important;
  }
}
.comingsoon{
    overflow:hidden;    
}
.comingsoon img{
    height:auto;
    max-width: 80%;
   border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #fff;
    border-radius: 10px 10px 10px 10px;
}
.Comingheading h3{
    color: #fff;
    text-align: center;
    font-family: "Montserrat", Sans-serif;
    font-size: 48px;
    font-weight: 700;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 1.2em;
    letter-spacing: -0.5px;
}
@media screen and (max-width: 973px){
    .Comingheading h3{
        font-size: 22px;
    }
    .comingsoon img{
        max-width:100%;
        border-radius: 5px 5px 5px 5px;       
    }
}
.goog-te-combo {
  display: none !important;
}
#google_translate_element .goog-te-combo {
  display: inline-block !important;
}
.navbar-toggler {
  border: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}
.firstnav {
  width: 80%;
  border-right-style: solid;
  border-left-style: solid;
  border-color: #d42121;
  border-width: thick;
  background-color: #EEA820;
}
#navbarNav {
  justify-content: center;
  align-items: center;
  width: 80%;
  background-color: #d42121;
  position: relative;
  border-radius: 2%;
  border: white solid 1px;
  box-shadow: rgba(70, 70, 70, 0.45) 0px 25px 20px -20px;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 0, 0, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-Nav .nav-item {
  padding: 3px;
}
.nav-item .navbar-brand img {
  background-color: white;
}
.navbar-scroll .nav-link,
.navbar-scroll .navbar-toggler-icon {
  color: #d42121;
}
.nav-link:focus {
  color: #EEA820;
}
#yellowline {
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;}

.navbar-nav {
  align-items: center;
}
.navbar-brand {
  font-size: 15px;
  font-weight: 500;
}
.nav-item {
  font-size: 18px;
  font-weight: 400;
  margin-left: 18px;
}
.nav-item a:hover {
  font-weight: 500;
  color: #EEA820;
}
.nav-item a {
  color: white;
  font-weight: 500;
  font-family: "Montserrat", Sans-serif;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #d42121;
}
.nav-item .logo img {
  height: 60px;
  position: absolute;
  top: -35px;
}
#logo {
  width: 100px;
}
.tophead_img1 {
  height: 86px;
  position: absolute;
  top: -45px;
  left: 92px;
  animation-name:infiniteleftright;
  animation-duration:3s;
  animation-iteration-count:infinite;
  animation-timing-function:ease;
  transition: all .3s;
}
.tophead_img2 {
  height: 86px;
  position: absolute;
  top: -45px;
  right: 92px;
  animation-name:infiniteleftright;
  animation-duration:3s;
  animation-iteration-count:infinite;
  animation-timing-function:ease;
  transition: all .3s;
}
@media screen and (max-width: 973px) {
  #yellowline {
    background-color: #ffff;
  }
  .textanim h2 {
    position: relative;
    font-size: 5vw;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    color: #d42121;
    text-transform: uppercase;
  }
  #home {
    position: relative;
    top: 41px;
  }
  .navbar {
    position: absolute;
    width: 100%;
  }
  .mbhidden {
    display: none;
  }
}
@media screen and (max-width: 1440px) {
  .tophead_img1 {
    height: 86px;
    position: absolute;
    top: -45px;
    left: -5px;
  }
  .tophead_img2 {
    height: 86px;
    position: absolute;
    top: -45px;
    right: -5px;
  }
}
.contactInfo {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
}
.Address {
  color: White;
}
.contactInfo .box {
  position: relative;
  padding: 20px 0;
}
.contactInfo .box .icon {
  min-width: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 30px;
  padding: 20px;
}
.contactInfo .box .icon i {
  color: #ffcc04;
}
.pt-5 {
  padding-top: 5rem !important;
}
.contactInfo .box .text {
  display: flex;
  font-size: 16px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
}
.contactInfo .box .text a {
  text-decoration: none;
  text-wrap: wrap;
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align:center;
  text-transform: none;
  font-style: normal;
  line-height: 1.7em;
}
.contact-form {
  width: 70%;
}
.mb-4 .form-label {
  float: left;
}
.sending {
  color: #000; 
    font-family: "Calistoga", serif;
    font-weight: 200;
}
.success {
  color: green; 
  font-family: "Calistoga", serif;
  font-weight: 200;
}
.error {
  color: #dc3545; 
  font-family: "Calistoga", serif;
  font-weight: 200;
}
.TopHeading1 {
  font-weight: 800;
  text-transform: uppercase;
  width: 100%;
  text-shadow: 0.08em 0.08em 0em rgb(66 66 66 / 20%);
  text-align: center;
  white-space: nowrap;
  line-height: 1.1em;
  color: #d42121;
}
.Contact_top p {
  font-size: 16px;
  font-weight: 500;
  color: #666;
  line-height: 1.7em;
  -webkit-font-smoothing: antialiased;
}
.contactbg h2 {
  color: #ffffff;
  font-family: "Calistoga", serif;
  line-height: 1.7em;
  font-weight: 300;
}
.formimage {
  width: 20%;
  position: absolute;
  top: 992px;
  right: 500px;
  animation-name: airoplane;
  animation-delay: 10s;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  transition: all 6s;
}
@keyframes airoplane {
  0% {
    transform: translate(800px, -400px);
  }
  100% {
    transform: translate(-1000px, 400px);
  }
}
.comingsoonimage {
  width: 20%;
  position: absolute;
  top: 2692px;
  right: 0px;
  animation-name: comingsoon;
  animation-delay: 10s;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  transition: all 6s;
}
@keyframes comingsoon {
  0% {
    transform: translate(0px, -300px);
  }
  100% {
    transform: translate(-1500px, 400px);
  }
}
.carrun img {
  width: 20%;
  position: absolute;
  left:0px;
  bottom:-18px;  
  animation-name: carrun;
  animation-delay: 0s;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  transition: left 0.3s, bottom 0.3s;
}
@keyframes carrun {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(1480px);
  }
}
@media screen and (max-width: 760px) {
  .carrun img {
    width: 40%;
    left: 0px;
    bottom: -9px;
    animation-delay: 0s;
    animation-duration: 24s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transition: left 0.3s, bottom 0.3s;
  }
  @keyframes carrun {
    0% {
      transform: translateX(-70px);
    }
    100% {
      transform: translateX(350px);
    }
  }
  .formimage {
    width: 60%;
    position: absolute;
    top: 1800px;
    right: 0px;
    animation-name: airoplane;
    animation-delay: 0s;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all 6s;
  }
  @keyframes airoplane {
    0% {
      transform: translate(20px, -450px);
    }
    100% {
      transform: translate(-380px, 20px);
    }
  }
  .comingsoonimage {
    width: 40%;
    position: absolute;
    top: 1370px;
    right: 0px;
    animation-name: comingsoon;
    animation-delay: 0s;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all 6s;
  }
  @keyframes comingsoon {
    0% {
      transform: translate(10px, -180px);
    }
    100% {
      transform: translate(-320px, 20px);
    }
  }
}



.footer-h2 h2{
  color: white;
}
.icon, .fa{
 color: white;
 font-size: 1.5rem;
}
.social-icon {
  font-size: 2rem;
}
.f-logo{
  height: 90px;
}
h6{
  color: white;
  margin-top: 15px;
  line-height: 26px;
  text-align: justify;
}
.Add{
  line-height: 26px;
  text-align: justify;
}
.footer-h2 h2{
  color: #FFCC04;
  text-align: center;
  font-family: "Montserrat", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: -0.5px;
}
.footer-h3 h3{
  color: #d42121;
  text-align: center;
  font-family: "Montserrat", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: -0.5px;
}
.footer-h3 h5{
  color: #928781;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: -0.5px;
}
.Q-link{
  line-height: 40px;
}
.callnumber .callnu{
  text-decoration: none;
    font-weight: bold;
    font-size: 20px;
}
.callnumber .callnu i {
  margin-right:12px;
}
.fa-phone-volume{
  font-size:1.7rem;
}
@media screen and (max-width: 973px){
  .footer-h3 button{
    padding: 10px 20px;
  } 
  .btn_text{
    font-size: 15px;
  }
  .footer-h3 h3{
    font-size: 22px;
    font-weight: 600;
  }
  .footer-h3 h5{
    font-size: 16px;
  }
}
html {
  scroll-behavior: smooth;
}
.topbtn {
  position: absolute;
  right: 28px;
  margin-top: -5rem;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #ffcc04;
  cursor: pointer;
  z-index: 9999;
  opacity: 0.9;
}
i {
  color: #ffffff;
  font-size: larger;
}
a {
  color: rgb(255 255 255);
  text-decoration: underline;
}
.whatsapp-chat-container {
  position: fixed;
  bottom: 90px;
  right: 28px;
  z-index: 1000;
}
.whatsapp-icon {
  background-color: #25d366;
  border: none;
  border-radius: 50%;
  padding: 5px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-box {
  position: absolute;
  bottom: 70px;
  right: 0;
  width: 250px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}
.chat-box textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  resize: none; /* Prevent resizing */
}
.send-button {
  background-color: #25d366;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end; /* Aligns button to the right */
}
.chat-box {
  position: absolute;
  bottom: 70px; /* Adjust based on your preference */
  right: 0;
  width: 250px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
}
.chat-box textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}
.send-button {
  background-color: #25d366;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}
@media screen and (max-width: 973px) {
  .whatsapp-icon {
    background-color: #25d366;
    border: none;
    border-radius: 50%;
    padding: 7px;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .whatsapp-chat-container {
    position: fixed;
    bottom: 81px;
    right: 6px;
    z-index: 1000;
  }
  .topbtn {
    position: absolute;
    right: 7px;
    margin-top: -5rem;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #ffcc04;
    cursor: pointer;
    z-index: 9999;
    opacity: 0.9;
  }
}
.leader_img img {
  width: 100%;
}
.center-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.center-vertical h2 {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}
.smallscreen {
  display: none;
}
@media (max-width: 767px) {
  .largescreen {
    display: none;
  }
  .smallscreen {
    display: block;
  }
  .founder_height {
    height: 50vh;
  }
}
@media (min-width: 768px) {
  .smallscreen {
    display: none;
  }
  .flex_left {
    align-items: flex-start;
  }
  .flex_right {
    align-items: flex-end;
  }
}

.slide-track {
  animation: scroll $animationSpeed linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.scroll-container {
  overflow-y: scroll;
  height: 100vh;
  position: relative;
  width: 100%;
  top: -91px;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scroll-container::-webkit-scrollbar {
  display: none;
}
.panel {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: start;
  overflow: hidden;
}
.panel p{
  width:80%;
}
.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}
.spanheading {
  color: white;
  background-color: #d42121a3;
}
.scrollingtext h3 {
  color: #d42121;
  text-align: center;
  font-family: "Calistoga", serif;
  font-weight: 300;
  font-style: normal;
  font-size: 4vw;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.5em;
  letter-spacing: -0.5px;
}
.scrollingtext h3 span:nth-child(1) {
  color: white;
  background-color: #d42121a3;
  padding: 0 18px 0 20px;
  margin-right: 12px;
}
.scrollingtext h3 span:nth-child(2) {
  color: #444040;
  padding: 0 0 0 18px;
}
.scrollingtext h3 span:nth-child(3) {
  color: white;
  margin-left: 22px;
  background-color: #d42121a3;
  padding: 0 18px 0 18px;
}
.scroll-indicator i {
  color: #ffffff94;
  font-size: 4rem;
}
.scroll-up-indicator i {
  color: #d42121cc;
  font-size: 4rem;
}
.scroll-indicator,
.scroll-up-indicator {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  color: red;
  font-size: 30px;
  cursor: pointer;
  animation: bounce 2s infinite;
}
.dot-navigation {
  position: fixed;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.dot.active {
  background-color: #d42121;
}
.productnu {
  text-align: center;
  font-family: "Montserrat", Sans-serif;
  font-size: 14px;
}
.allproduct li {
  text-align:left;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4em;
  margin:10px 0px 10px 15px;
  list-style-type: square;
}
.quality{
  background:#d42121a3;
  padding: 0 18px 0 20px;
    margin:0 4px 0 4px;
}
.allproduct_img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
}
.allproduct_img img {
  width: 100%;
  height: auto;
}
.allproduct_img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgb(212 33 33 / 68%),
    rgb(238 168 32 / 70%)
  );
  z-index: 1;
  pointer-events: none;
}
.allproduct_h3 {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.allproduct_h3 span{
  background-color:#EEA820 !important;
}
.allproduct_h3 h3 {
  font-size: 38px;
  color: white;
  width: 100%;
  box-sizing: border-box; 
}
.image-container {
  position: relative;
  display: inline-block;
}
.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.overlay-text {
  position: absolute;
  bottom: 18%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5rem;
  background-color:#d42121a3;
  font-family: "Calistoga", serif;
  font-weight: 300;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  z-index: 1;
  animation: bounce 2s infinite;
}
.overlay-ul {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5rem;
  font-family: "Calistoga", serif;
  font-weight: 300;
  border-radius: 5px;
  z-index: 1;
   
  padding-left:3rem;
  padding-right:3rem;
}
.overlay-ul ul{
  margin-right: 40px;
}
.allproduct_h3 span:nth-of-type(1){
  color:#ffa622 !important;
  background-color:#ffffffde !important;
}
.allproduct_h3 span:nth-of-type(2){
  color:green !important;
  background-color:#ffffffde !important;
}
.allproduct_h3 span:nth-of-type(3){
  color:red !important;
  background-color:#ffffffde !important;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-10px);
  }
  60% {
    transform: translateX(-50%) translateY(-5px);
  }
}
@media screen and (max-width: 768px) {
  .scrollingtext h3 {
    font-size: 18px;
    padding: 0;
    line-height: 1.5;
  }
  .dot {
    width: 8px;
    height: 8px;
  }
  .dot-navigation {
    grid-gap: 8px;
    gap: 8px;
  }
  .scroll-indicator,
  .scroll-up-indicator {
    bottom: 15px;
    font-size: 24px;
  }
  .scroll-container {
    top: 0px;
  }
  .scroll-indicator i,
  .scroll-up-indicator i {
    font-size: 3rem;
  }
  .allproduct ul {
    padding: 0;
  }
  .allproduct li {
    font-size: 10px;
    margin: 9px 0px 0px -1px;
  }
  .overlay-text {
    font-size: 12px;
    bottom: 70px;
  }
  .mobile-image {
    content: url('/public/product all website.webp');
  }
  .allproduct_h3 h3{
    font-size:18px;
    padding:0;
  }
  .overlay-ul ul{
    margin-right: 2px;
  }
  .overlay-ul{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .maxwidth60{
    max-width:70%;
  }
}
@media screen and (max-width: 973px) {
  .scrollingtext h3 {
    font-size: 22px;
  }
  .scrollingtext h3 span:nth-child(1) {
    padding: 0 18px 0 20px;
    margin-right: 8px;
  }
  .scrollingtext h3 span:nth-child(2) {
    padding: 0 0 0 0px;
  }
  .scrollingtext h3 span:nth-child(3) {
    margin-left: 8px;
    padding: 0 18px 0 18px;
  }
  .scroll-indicator {
    position: fixed;
    bottom: 20px;
    left: 50%;
    font-size: 30px;
  }
}
