.contactInfo {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
}
.Address {
  color: White;
}
.contactInfo .box {
  position: relative;
  padding: 20px 0;
}
.contactInfo .box .icon {
  min-width: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 30px;
  padding: 20px;
}
.contactInfo .box .icon i {
  color: #ffcc04;
}
.pt-5 {
  padding-top: 5rem !important;
}
.contactInfo .box .text {
  display: flex;
  font-size: 16px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
}
.contactInfo .box .text a {
  text-decoration: none;
  text-wrap: wrap;
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align:center;
  text-transform: none;
  font-style: normal;
  line-height: 1.7em;
}
.contact-form {
  width: 70%;
}
.mb-4 .form-label {
  float: left;
}
.sending {
  color: #000; 
    font-family: "Calistoga", serif;
    font-weight: 200;
}
.success {
  color: green; 
  font-family: "Calistoga", serif;
  font-weight: 200;
}
.error {
  color: #dc3545; 
  font-family: "Calistoga", serif;
  font-weight: 200;
}
.TopHeading1 {
  font-weight: 800;
  text-transform: uppercase;
  width: 100%;
  text-shadow: 0.08em 0.08em 0em rgb(66 66 66 / 20%);
  text-align: center;
  white-space: nowrap;
  line-height: 1.1em;
  color: #d42121;
}
.Contact_top p {
  font-size: 16px;
  font-weight: 500;
  color: #666;
  line-height: 1.7em;
  -webkit-font-smoothing: antialiased;
}
.contactbg h2 {
  color: #ffffff;
  font-family: "Calistoga", serif;
  line-height: 1.7em;
  font-weight: 300;
}
.formimage {
  width: 20%;
  position: absolute;
  top: 992px;
  right: 500px;
  animation-name: airoplane;
  animation-delay: 10s;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  transition: all 6s;
}
@keyframes airoplane {
  0% {
    transform: translate(800px, -400px);
  }
  100% {
    transform: translate(-1000px, 400px);
  }
}
.comingsoonimage {
  width: 20%;
  position: absolute;
  top: 2692px;
  right: 0px;
  animation-name: comingsoon;
  animation-delay: 10s;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  transition: all 6s;
}
@keyframes comingsoon {
  0% {
    transform: translate(0px, -300px);
  }
  100% {
    transform: translate(-1500px, 400px);
  }
}
.carrun img {
  width: 20%;
  position: absolute;
  left:0px;
  bottom:-18px;  
  animation-name: carrun;
  animation-delay: 0s;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  transition: left 0.3s, bottom 0.3s;
}
@keyframes carrun {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(1480px);
  }
}
@media screen and (max-width: 760px) {
  .carrun img {
    width: 40%;
    left: 0px;
    bottom: -9px;
    animation-delay: 0s;
    animation-duration: 24s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transition: left 0.3s, bottom 0.3s;
  }
  @keyframes carrun {
    0% {
      transform: translateX(-70px);
    }
    100% {
      transform: translateX(350px);
    }
  }
  .formimage {
    width: 60%;
    position: absolute;
    top: 1800px;
    right: 0px;
    animation-name: airoplane;
    animation-delay: 0s;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all 6s;
  }
  @keyframes airoplane {
    0% {
      transform: translate(20px, -450px);
    }
    100% {
      transform: translate(-380px, 20px);
    }
  }
  .comingsoonimage {
    width: 40%;
    position: absolute;
    top: 1370px;
    right: 0px;
    animation-name: comingsoon;
    animation-delay: 0s;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all 6s;
  }
  @keyframes comingsoon {
    0% {
      transform: translate(10px, -180px);
    }
    100% {
      transform: translate(-320px, 20px);
    }
  }
}


