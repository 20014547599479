.people img {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.people img.slide-up {
  opacity: 1;
  transform: translateY(0);
}
.scroll_hidden {
  overflow: hidden;
  position: absolute;
  margin-top: -28px;
}
.scroll {
  white-space: nowrap;
  margin: 0 2em;
}
.Map {
  position: relative;
}
.overlay-heading {
  position: absolute;
  top: 12%;
  left: 46%;
  width:50%;
  transform: translate(-50%, -50%);
  color: black !important;
  z-index: 1;
  text-align: center;
  padding: 0 10px;
}
.Map img {
  width: 100%;
  position: relative;
}
.image-right{
  text-align:right;
}
.image-right img{
  float:right;
}
.csMap{
  animation-name:infiniteupdown;
  animation-duration:3s;
  animation-iteration-count:infinite;
  animation-timing-function:ease-out;
  transition: all .3s;
}
@keyframes infiniteupdown{
  0%{
    transform:translateY(0px);
  }
  50%{
    transform:translateY(15px);
  }
  100%{
    transform:translateY(0px);
  }
}
@keyframes infiniteleftright{
  0%{
    transform:rotate(5deg);
  }
  50%{
    transform:rotate(-5deg);
  }
  100%{
    transform:rotate(5deg);
  }
}
.scroll div {
  display: flex;
  gap: 4em;
}
.txtmap {
  color: white;
  font-weight: bold;
  line-height: 10px;
  padding: 6px;
}
.scrollheading h3 span:nth-child(3){
  font-size: 40px !important;
  line-height: 70px !important;
  background: green !important;
}
@media screen and (max-width: 973px) {
  .logoanimation {
    width: 22%;
    position: relative;
    left: 50px;
  }
}
@media screen and (max-width:758px){
  .overlay-heading {
    font-size:9px !important;
  }
  .scrollheading h3 span:nth-child(3){
    font-size: 14px !important;
    line-height: 24px !important;
    background: green !important;
  }
}