@import url("https://fonts.googleapis.com/css2?family=Calistoga&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
  width: 100%;
  background: #eee;
  font-family: "Montserrat", "Poppins", sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
.justify {
  text-align: justify;
  color: #19171a;
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.7em;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  display: none;
}
.mainheading {
  color: #d42121;
  text-align: center;
  font-family: "Calistoga", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: -0.5px;
}
.centerheading h3 {
  color: #d42121;
  text-align: center;
  font-family: "Calistoga", serif;
  font-weight: 300;
  font-style: normal;
  font-size: 50px;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: -0.5px;
}
.mainheading span {
  font-family: "Montserrat", "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: black;
}
.line {
  width: 120px;
  height: 2px;
  background: #eea820;
  margin: 0px auto;
  border-radius: 5px;
}
.founder_txt {
  text-align: center;
  color: #19171a;
  font-family: "Montserrat", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.7em;
}
.subheding {
  text-align: justify;
  color: #fffffe;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.5em;
  letter-spacing: 0px;
}
.TopHeading {
  font-size: clamp(1.5rem, 2vw, 1rem);
  font-weight: 600;
  text-align: center;
  margin: 20px 0;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  padding-bottom: 13px;
}
.swiper {
  width: 110%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  flex-shrink: 0;
  width: 39% !important;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.wrapperr {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}
.wrapperr iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.swiper-slide img {
  display: block;
  width: 100%;
  box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.1) !important;
}
.textanim {
  display: flex;

  justify-content: center;
  align-item: center;
}
.textanim img {
  width: 9%;
  position: relative;
  top: -30px;
}
#textanim1 h2 {
  position: relative;
  top: 49px;
}

@keyframes animate {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.contactbg {
  background-image: url("CITY WEBSITE EDITS-01.webp");
}
.cBVEOn {
  position: relative;
  right: 0px;
  width: 287px;
  height: 346px;
}
@media screen and (max-width: 973px) {
  .swiper-slide {
    width: 64% !important;
  }
  .TopHeading1 {
    font-size: 22px;
  }
  .TopHeading {
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
  }
  .textanim img {
    width: 12%;
    position: relative;
    top: -16px;
  }
  #textanim1 h2 {
    position: relative;
    top: 68px;
    font-size: 4vw;
  }
  .image-container .image {
    border: 9px solid #fff;
  }
  .gallery-tab .btn-warning {
    width: 100%;
    padding: 10px;
  }
  .mainheading {
    font-size: 22px;
    display: contents;
    text-align: center;
  }
  .text-center h2 {
    display: grid;
  }
  .centerheading h3 {
    font-size: 28px;
  }
  .usp_heading h3 {
    font-size: 28px;
  }
  .justify {
    font-size: 12px;
  }
}
.instaframe {
  height: auto;
  max-width: 100%;
}
.whatsapp-chat-container {
  animation-name: infiniteupdown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  transition: all 0.3s;
}
.readbtn{
    border: none;
    outline: none;
    outline-style: none;
    font-size: 14px;
    color: #999090;
    font-family: "Montserrat", Sans-serif;
    font-weight: 600;
}
.btn {
  background-color: #d42121;
  border: 2px solid #d42121;
  border-radius: 15px;
  padding: 10px 24px 10px 24px;
  font-size: 18px;
  color: #fff;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.btn_text {
  text-decoration: none;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.btn:hover {
  background-color: #eea820;
  border: 2px solid #eea820;
  color: #fff;
}
.visiondiv {
  color: #ffcc04;
  text-align: center;
  font-family: "Montserrat", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: -0.5px;
}
#home {
  position: relative;
  top: -92px;
}
.taggbox_main a {
  display: none !important;
}
.productvideo{
  position:absolute;
}
.productvideo h3{
  color: #fff;
    font-family: "Calistoga", serif;
    font-weight: 300;
    font-size: 50px;
}
.swiper-container-wrapper {
  position: relative;
}
.custom-swiper-button {
  position: absolute;
  top: 50%;
  transform: translateY(-15%);
  z-index: 10;
  color:#fff;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}
.swiper-button-prev {
  left: 30px;
  color:#fff !important;
}
.swiper-button-next {
  right: 30px;
  color:#fff !important;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.centerheading {
  text-align: center;
}

@media screen and (max-width: 768px){
  .productvideo h3{
      font-size: 20px;
  }
  .btn {
    border-radius: 8px;
    padding: 6px;
    font-size: 14px;
}
}
@media screen and (min-width: 1400px){
  .productvideo{
    position: absolute;
    margin-top: 98px;
  }  
}