.goog-te-combo {
  display: none !important;
}
#google_translate_element .goog-te-combo {
  display: inline-block !important;
}
.navbar-toggler {
  border: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}
.firstnav {
  width: 80%;
  border-right-style: solid;
  border-left-style: solid;
  border-color: #d42121;
  border-width: thick;
  background-color: #EEA820;
}
#navbarNav {
  justify-content: center;
  align-items: center;
  width: 80%;
  background-color: #d42121;
  position: relative;
  border-radius: 2%;
  border: white solid 1px;
  box-shadow: rgba(70, 70, 70, 0.45) 0px 25px 20px -20px;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 0, 0, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-Nav .nav-item {
  padding: 3px;
}
.nav-item .navbar-brand img {
  background-color: white;
}
.navbar-scroll .nav-link,
.navbar-scroll .navbar-toggler-icon {
  color: #d42121;
}
.nav-link:focus {
  color: #EEA820;
}
#yellowline {
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;}

.navbar-nav {
  align-items: center;
}
.navbar-brand {
  font-size: 15px;
  font-weight: 500;
}
.nav-item {
  font-size: 18px;
  font-weight: 400;
  margin-left: 18px;
}
.nav-item a:hover {
  font-weight: 500;
  color: #EEA820;
}
.nav-item a {
  color: white;
  font-weight: 500;
  font-family: "Montserrat", Sans-serif;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #d42121;
}
.nav-item .logo img {
  height: 60px;
  position: absolute;
  top: -35px;
}
#logo {
  width: 100px;
}
.tophead_img1 {
  height: 86px;
  position: absolute;
  top: -45px;
  left: 92px;
  animation-name:infiniteleftright;
  animation-duration:3s;
  animation-iteration-count:infinite;
  animation-timing-function:ease;
  transition: all .3s;
}
.tophead_img2 {
  height: 86px;
  position: absolute;
  top: -45px;
  right: 92px;
  animation-name:infiniteleftright;
  animation-duration:3s;
  animation-iteration-count:infinite;
  animation-timing-function:ease;
  transition: all .3s;
}
@media screen and (max-width: 973px) {
  #yellowline {
    background-color: #ffff;
  }
  .textanim h2 {
    position: relative;
    font-size: 5vw;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    color: #d42121;
    text-transform: uppercase;
  }
  #home {
    position: relative;
    top: 41px;
  }
  .navbar {
    position: absolute;
    width: 100%;
  }
  .mbhidden {
    display: none;
  }
}
@media screen and (max-width: 1440px) {
  .tophead_img1 {
    height: 86px;
    position: absolute;
    top: -45px;
    left: -5px;
  }
  .tophead_img2 {
    height: 86px;
    position: absolute;
    top: -45px;
    right: -5px;
  }
}