.visiondiv h4{
    color: #d42121;
    font-size: 30px;
    font-weight: 600;
    text-align:center;
}
.visiondiv {
    margin: 0px 0px 40px 0px;
    padding: 20px 20px 20px 25px;
    background-color: #ffffff;
    border-radius: 10px 10px 10px 10px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }
  .visiondiv p {
    text-align: justify;
    color: #19171a;
    font-family: "Montserrat", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 1.7em;
  }
  .visiondiv:hover{
    background-color: #ffffffbd;
  }
  .VisionHeading h3{
    color: #d42121;
    text-align: center;
    font-family: "Calistoga", serif;
    font-weight: 300;
    font-style: normal;
    font-size: 34px;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 1.5em;
    word-spacing: 2px;
    letter-spacing: 0.2px;

  }
  .VisionHeading h3 span:nth-child(1) {
    color:white ; 
    background-color:#d42121a3;
    padding: 0 18px 0 20px;
    margin-right:12px;
  }  
  .VisionHeading h3 span:nth-child(2) {
    color:black;
    padding: 0 0 0 18px;
  }
  .VisionHeading h3 span:nth-child(3) {
    color:white ; 
    margin-left:22px;
    background-color:#d42121a3;
    padding: 0 18px 0 18px;
  }
  .VisionHeading h3 span:nth-child(4){
    color:white ; 
    background-color:#d42121a3;
    padding: 0 18px 0 20px;
    margin-right:12px;
  }
  .visionimg{
    max-width:50%;
    height:auto;
  }
  .Visioncontainer{
    position:relative;
    top:-95px;
  }
  .visionhandimg{
    position: absolute;
    max-width: 45%;
    top: 80px;
    right: -22px;
  }
  .missioncontainer{
    margin-top: 124px;
  }
  @media screen and (max-width: 973px){
     .VisionHeading h3{
        font-size: 18px;
        
      }  .visionhandimg{
        position: absolute;
        max-width: 45%;
        top: 118px;
        right: 0px;
      } .missioncontainer{
        margin-top: 100px;
      }
      .VisionHeading h3 span:nth-child(1) {
        color:white ; 
        background-color:#d42121a3;
        padding: 0 6px 0 6px;
        margin-right:4px;
      }      
      .VisionHeading h3 span:nth-child(2) {
        color:black;
        padding: 0 0 0 0px;
      }
      .VisionHeading h3 span:nth-child(3) {
        color:white ; 
        margin:0px;
        background-color:#d42121a3;
        padding: 0 6px 0 6px;
      }
      .VisionHeading h3 span:nth-child(4){
        padding: 0 6px 0 6px;
        margin:0px;
      }
  }
  @media screen and (max-width: 475px){   
      .visionhandimg{
        position: relative;
        max-width: 112%;
        top:0px;
        right: -42px;
      }
      .missioncontainer{
        margin-top: 29px;
      }
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0; 
    }
    100% {
      transform: translateX(0); 
      opacity: 1;
    }
  }
  .animate-slide-in {
    animation: slideInFromRight 2s ease-out; 
  }